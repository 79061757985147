import { Row, Col, Typography, Button, Layout, Divider } from "antd";
import { IoLogoFacebook, IoLogoInstagram, IoLogoYoutube } from "react-icons/io";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "./styles.css";
import {
  DashboardOutlined,
  ShopOutlined,
  TeamOutlined,
  CalendarOutlined,
  SettingOutlined,
  HeartFilled,
  NotificationOutlined,
  LineChartOutlined,
  InteractionOutlined,
  BarsOutlined,
  ApartmentOutlined,
  ArrowUpOutlined,
  MehOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
const { Text, Title } = Typography;
const { Footer: AntFooter } = Layout;

export default function NotFound() {
  return (
    <>
      <motion.div
        className="box"
        animate={{
          scale: [1, 2, 2, 1, 1],
          rotate: [0, 0, 180, 180, 0],
          borderRadius: ["0%", "0%", "50%", "50%", "0%"],
        }}
        transition={{
          duration: 2,
          ease: "easeInOut",
          times: [0, 0.2, 0.5, 0.8, 1],
          repeat: Infinity,
          repeatDelay: 1,
        }}
      ></motion.div>

      <Row
        style={{
          width: "100%",
          minHeight: "12vh",
          position: "absolute",
          bottom: 30,
        }}
        justify={"center"}
        align={"middle"}
      >
        <Button style={{ textAlign: "center", justifyContent: "center" }}>
          <Text>
            Böyle biri bulamadık <MehOutlined />
          </Text>
        </Button>

      </Row>
    </>
  );
}
