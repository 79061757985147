export const CAMPAIGN_STATUS = {
  NOTSTARTED: "NOTSTARTED",
  WAITING: "WAITING",
  ONGOING: "ONGOING",
  EXPIRED: "EXPIRED",
  CANCELED: "CANCELED",
  ALL: "ALL",
};
export const TYPE_OF_SELLER = {
  PRODUCT: "PRODUCT",
  PLACE: "PLACE",
  APP: "APP",
  OTHER : "OTHER"
};