import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { AuthProvider } from "./context/AuthContext";
import { ConfigProvider } from "antd";
import { colors } from "./assets/colors";
import { I18nextProvider } from "react-i18next";
import { initReactI18next } from "react-i18next";
import i18n from "./i18n";
import { MediaQueryProvider } from "./context/MediaQueryContext";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: colors.darkBlue,
                headerFilterHoverBg: "red",
                headerColor: "white",
                headerBorderRadius: 10,
              },
              Button: {
                borderRadius: 20,
                borderRadiusLG: 25,
              },
              Card: {
                borderRadius: 20,
                borderRadiusLG: 15,
              },
              Select: {
                borderRadius: 20,
                borderRadiusLG: 20,
              },
              Dropdown: {
                paddingBlock: 6,
              },
              Collapse: {
                contentBg: "red",
                borderRadiusLG: 0,
                motionDurationMid: "0.5s",
              },
              Menu: {
                darkItemBg: colors.middleBlue,
                darkItemHoverBg: colors.grayDark,
                darkItemSelectedBg: colors.middleBlue,
                horizontalItemBorderRadius: 0,
                itemBorderRadius: 0,
              },
            },
          }}
        >
          <AuthProvider>
            <MediaQueryProvider >
              <App />
            </MediaQueryProvider>
          </AuthProvider>
        </ConfigProvider>
      </QueryClientProvider>
    </Provider>
  </I18nextProvider>
);
