import { useMutation, useQuery } from "@tanstack/react-query";
import { DashboardServices } from "./service";
import { useRef } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom


export const useGetLinkInfoForVisiter = (nickName: string) => {
    const lastCallRef = useRef<number | null>(null);
    const navigate = useNavigate(); // Initialize useNavigate

    console.log(lastCallRef)

    return useQuery<any, Error>(['getLinkInfoForVisiterKey', nickName], async () =>
        await DashboardServices.getLinkInfoForVisiter(nickName), {
        enabled: !!nickName,
        staleTime: 60 * 60 * 1000, // 1 hour in milliseconds
        refetchOnWindowFocus: false, // Prevent refetching on window focus
        refetchOnMount: false, // Prevent refetching when the component mounts
        onSuccess: (data) => {

            if (data.isFound) {
                const currentTime = Date.now(); // Get the current timestamp

                // Get the last call time from local storage
                const lastCallTime = localStorage.getItem(`lastCall_${nickName}`);

                // Check if the last call was made within the last hour
                if (!lastCallTime || currentTime - Number(lastCallTime) >= 60 * 60 * 1000) {
                    DashboardServices.createViewMetricByInfluencerNickName(nickName);
                    localStorage.setItem(`lastCall_${nickName}`, currentTime.toString()); // Update the last call timestamp in local storage
                }
            }

        },
        onError: (error) => {
            console.log(error)
            navigate('/not-found');

        }
    }
    );
};

export const useClickedProductByVisiter = (onSuccess?: (data: any) => void, onError?: (error: Error) => void) => {
    return useMutation<any, Error, any>(
        
        (data) => DashboardServices.clickedProductByVisiter(data)
    );
};


