const BASE_DEV_URL = "https://vauv-7a9434039177.herokuapp.com/v1/";
const BASE_LOCAL_URL = "http://localhost:3000/v1/";


export const CURRENT_URL = BASE_DEV_URL;

export const EXP_SELLER_ID = '670a50a5906963ab2e24e184';
export const SELLER_ID = '670a50a4906963ab2e24e182';
export const INFLUENCER_ID = '670a50a4906963ab2e24e182';

export const CATEGORY_ID = '670a50f4906963ab2e24e18f';


export const ENDPOINTS = {
  visiter: {
    getLinkInfoForVisiter : 'activeAds/influencer/nickname',
  },
  metrics: {
    createViewMetricByInfluencerNickName  :'viewMetric/influencer/nickname',
    clickedProductByVisiter  :'clickMetric',
  },
  account: {
    getSellerProfileBySellerId: 'profile',
  },
  coupon: {
    useCouponForSellerWithCouponId: 'coupon/useCouponForSellerWithCouponId',
  },
  currentBudget: {
    getCurrentBudgetWithSellerId: 'currentBudget/getCurrentBudgetWithSellerId',
  },
  product: {
    createProduct: 'product/createProduct',
    getSellerProducts: 'product/seller',
  },
  campaign: {
    getSellerCampaign: 'campaign/getCampaignsWithSellerId',
    createCampaign: 'campaign/',
    getAvailableCampaignsForInfluencerWithInfluencerId: 'campaign/getAvailableCampaignsForInfluencerWithInfluencerId',
    makeCampaginActiveForInfluencer : 'activeAds/makeCampaginActiveForInfluencer',
    getActiveAdsForInfluencerWithInfluencerId : 'activeAds/getActiveAdsForInfluencerWithInfluencerId'

  },
  partners: {
    getInvitationWithEmailAndBusinessId: 'partners/getInvitationWithEmailAndBusinessId',
    activateTeamMemberWithPassword: 'partners/activateTeamMemberWithPassword',
    getAllAutomatedByBusinessId: 'partners/getAllAutomatedByBusinessId'
  },
  news: {
    getAllNews: 'news/getAllNews',
  },
  clients: {
    getAllClientsByBusinessId: 'clients/getAllClientsByBusinessId',
    createClientsByBusinessId: 'clients/createClientsByBusinessId',

  },
  businessClosedPeriod: {
    getAllClosedPeriodByBusinessId: 'businessClosedPeriod/getAllClosedPeriodByBusinessId',
    addClosedPeriodByBusinessId: 'businessClosedPeriod/addClosedPeriodByBusinessId',
    deleteClosedPeriod: 'businessClosedPeriod/deleteClosedPeriod',

  },
  timeOffTypes: {
    getAllTimeOffTypesByBusinessId: 'timeOffTypes/getAllTimeOffTypesByBusinessId',
    addTimeOffTypesByBusinessId: 'timeOffTypes/addTimeOffTypesByBusinessId',
    deleteTimeOffTypes: 'timeOffTypes/deleteTimeOffTypes',
  },
  cancellationReason: {
    getAllCancellationReasonByBusinessId: 'cancellationReason/getAllCancellationReasonByBusinessId',
    addCancellationReasonByBusinessId: 'cancellationReason/addCancellationReasonByBusinessId',
    deleteCancellationReason: 'cancellationReason/deleteCancellationReason',

  },
  permission: {
    getAllPermissionByBusinessId: 'permission/getAllPermissionByBusinessId',
    updateAllPermissionByBusinessId: 'permission/updateAllPermissionByBusinessId',
  },
  blockedType: {
    getAllBlockedTypesByBusinessId: 'blockedType/getAllBlockedTypesByBusinessId',
    addBlockedTypesByBusinessId: 'blockedType/addBlockedTypesByBusinessId',
    deleteBlockedTypes: 'blockedType/deleteBlockedTypes',

  },
  calendar: {
    getUserAppointmentByBusinessInfoId: 'userAppointment/getUserAppointmentByBusinessInfoId',
    getUserAppointmentWithSelectedDateByBusinessInfoId: 'userAppointment/getUserAppointmentWithSelectedDateByBusinessInfoId',
    getAvailableTimesForAppointment: 'businessTeamShift/getAvailableTimesForAppointment',
    createUserAppointment: 'userAppointment/createUserAppointment'
  },
  businessSettings: {
    getBusinessInfoByBusinessId: 'businessInfo/getBusinessInfoByBusinessId',
    updateBusinessInfoByBusinessId: 'businessInfo/updateBusinessInfoByBusinessId'
  },
  auth: {
    registerBusiness: 'auth/register',
    loginBusiness: 'auth/login',
    refreshToken: 'auth/refresh-tokens-business'
  },
  userAccountProfile: {
    getBusinessContactAndSecurityInfo: 'business/getBusinessContactAndSecurityInfo',
  },
  team: {
    getAllTeamByBussinesId: 'businessTeam/getAllTeamByBussinesId',
    createTeam: "/businessTeam/createTeam",
    deleteTeam: "/businessTeam/deleteTeam",
    updateTeam: "/businessTeam/updateTeam",
    getAllTeamShiftWithWeekDateByBussinesId: "/businessTeamShift/getAllTeamShiftWithWeekDateByBussinesId",
    updateTeamShiftByTeamShiftId: "/businessTeamShift/updateTeamShiftByTeamShiftId",
    getClosedPeriodWithWeekDatesByBusinessId: "businessClosedPeriod/getClosedPeriodWithWeekDatesByBusinessId"
  },
  service: {
    getAllServiceByBusinessId: "/businessService/getAllServiceByBusinessId",
    createService: "/businessService/createService",
    deleteService: "/businessService/deleteService",
    updateService: "/businessService/updateService",
    createSubService: "/businessService/createSubServiceByServiceId",
    deleteSubService: "/businessService/deleteSubService",
    updateSubService: "/businessService/updateSubService",
  },
  review: {
    getReviewQueryByBusinessId: 'review/getReviewQueryByBusinessId',
  },
  dashboard: {
    getUserTodayNextAppointmnetsByBusinessInfoId: '/userAppointment/getUserTodayNextAppointmnetsByBusinessInfoId',
    getUpcomingUserAppointmnetsByBusinessInfoId: '/userAppointment/getUpcomingUserAppointmnetsByBusinessInfoId',
    getUserAppointmentsActivityByBusinessInfoId: '/userAppointment/getUserAppointmentsActivityByBusinessInfoId',
    getBusinessTopServiceByBusinessInfo: '/userAppointment/getBusinessTopServiceByBusinessInfo',
    getBusinessTopMemberByBusinessInfo: '/userAppointment/getBusinessTopMemberByBusinessInfo',
    appproveUserAppointmentForBusinessByUserAppointmentId: '/userAppointment/appproveUserAppointmentForBusinessByUserAppointmentId',
    cancelUserAppointmentByUserAppointmentId: '/userAppointment/cancelUserAppointmentByUserAppointmentId'

  },
  completed: {
    getBusinessInfoCompletedByBusinessInfoId: 'businessInfo/getBusinessInfoCompletedByBusinessInfoId'
  },
  onlineProfile: {
    getOpeningTimesByBusinessId: '/businessOpeningTimes/getOpeningTimesByBusinessId'
  },
  category : {
    getAllCategory : "/category/getAll"
  }
};

export const STORAGE_KEYS = {
  accessToken: "accessToken",
  refreshToken: "refreshToken",
  type: "type",

};


export const LOCALIZATION = {
  English: "English",
  Türkçe: "Türkçe",
};


export const APPOINTMENT_TYPE = {
  FROM_BUSINESS: 'FROM_BUSINESS',
  FROM_USER: 'FROM_USER',
  FROM_OWNER: 'FROM_OWNER',
};


export const CLIENT_TYPE = {
  BUSINESS_GENERATED: 'BUSINESS_GENERATED',
  APPOINTMENT_GENERATED: 'APPOINTMENT_GENERATED',
  WALK_IN: 'WALK_IN',
};
export const SIGN_IN_TYPE = {
  business: "business",
  teamMember: "teamMember",
};

export const APPOINTMENT_STATUS = {
  PENDING: 'pending',
  BOOKED: 'booked',
  CANCELED: 'canceled',
  EXPIRED: 'expired',
};

export const DATE_FORMAT_BASIC = "MM-DD-YYYY";
export const DATE_FORMAT_MM_DD = "MM-DD-YYYY";
export const DATE_FORMAT_ISO = "YYYY-MM-DD[T]00:00:00.000[Z]"
export const DATE_FORMAT_ISO_WITH_TIME_ZONE = 'YYYY-MM-DDT00:00:00.000Z';


