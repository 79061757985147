import axios from "axios";
import { CURRENT_URL } from "./constants";
import { getLocalStorage } from "./localStorage";


const HttpClient = axios.create({
  baseURL: CURRENT_URL,
  timeout: 10000,
  headers: {
    Accept: "application/json",
  },
});

HttpClient.interceptors.request.use(
  (config) => {
    const token = getLocalStorage();
    if (token.accessToken) {
      config.headers.Authorization = `Bearer ${token.accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default HttpClient;
