import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { default as English } from "./common/localization/en.json";
import { default as Türkçe } from "./common/localization/tr.json";

const resources = {
    English: { translation: English },
    Türkçe: { translation: Türkçe },
};
i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "English",
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
  compatibilityJSON: "v3",
});
export default i18n;
