import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';

// 1. Ekran boyutu tipi tanımlama
interface ScreenSize {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

// 2. Context tipi tanımlama
const MediaQueryContext = createContext<ScreenSize | undefined>(undefined);

// 3. Provider için prop tipi tanımlama
interface MediaQueryProviderProps {
  children: ReactNode;
}

// 4. Provider oluşturma
export const MediaQueryProvider: React.FC<MediaQueryProviderProps> = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1025 });

  const [screenSize, setScreenSize] = useState<ScreenSize>({
    isMobile,
    isTablet,
    isDesktop,
  });

  useEffect(() => {
    setScreenSize({ isMobile, isTablet, isDesktop });
  }, [isMobile, isTablet, isDesktop]);

  return (
    <MediaQueryContext.Provider value={screenSize}>
      {children}
    </MediaQueryContext.Provider>
  );
};

// 5. Custom hook: Context'e kolay erişim sağlama
export const useMediaQueryContext = (): ScreenSize => {
  const context = useContext(MediaQueryContext);
  if (!context) {
    throw new Error('useMediaQueryContext must be used within a MediaQueryProvider');
  }
  return context;
};
