import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Layout, Row } from "antd";
import Navbar from "./pages/Dashboard/components/Navbar";
import Dashboard from "./pages/Dashboard";

import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import { LOCALIZATION } from "./api/constants";
import NotFound from "./pages/Dashboard/components/NotFound";

const { Content } = Layout;

const App: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [isSizeSmall, setIsSizeSmall] = useState(null);

  const isAuth = true;

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 566) {
        setCollapsed(true);
        setIsSizeSmall(true);
      } else {
        setCollapsed(false);
        setIsSizeSmall(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <Router>
      <Routes>
        <Route path="/:username" element={<Dashboard />} />
        <Route path="/not-found" element={<NotFound />} />

      </Routes>
    </Router>
  );
};

export default App;
