import { useNavigate } from "react-router-dom";
import { STORAGE_KEYS } from "./constants";

export const setLocalStorageAfterSuccesLogin = (response) => {

    localStorage.setItem(STORAGE_KEYS.accessToken, response.tokens.access.token);
    localStorage.setItem(STORAGE_KEYS.refreshToken, response.tokens.refresh.token);

}

export const setLocalStorageAfterRefreshToken = (response) => {

    localStorage.setItem(STORAGE_KEYS.accessToken, response.tokens.access.token);
    localStorage.setItem(STORAGE_KEYS.refreshToken, response.tokens.refresh.token);
    localStorage.setItem(STORAGE_KEYS.type, response.business.type);

}

export const getLocalStorage = () => {

    const accessToken = localStorage.getItem(STORAGE_KEYS.accessToken);
    const refreshToken = localStorage.getItem(STORAGE_KEYS.refreshToken);
    const type = localStorage.getItem(STORAGE_KEYS.type);

    return { accessToken, refreshToken, type }
}


export const deleteLocalStorageAfterSuccesLoginOut = () => {

    localStorage.removeItem(STORAGE_KEYS.accessToken);
    localStorage.removeItem(STORAGE_KEYS.refreshToken);
}