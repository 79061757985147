import HttpClient from "../../../api/HttpClient";
import { ENDPOINTS } from "../../../api/constants";

export const DashboardServices = {

    getLinkInfoForVisiter: async (businessInfoId: string) => {
        try {
            const response = await HttpClient.get<any>(
                `${ENDPOINTS.visiter.getLinkInfoForVisiter}/${businessInfoId}`
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    clickedProductByVisiter: async (data: any): Promise<any> => {
        try {
            console.log(data)

            const response = await HttpClient.post<any>(`${ENDPOINTS.metrics.clickedProductByVisiter}`, data
            );
            console.log(response)
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    createViewMetricByInfluencerNickName: async (nickName: string): Promise<any> => {
        const data = {}
        try {
            const response = await HttpClient.patch<any>(`${ENDPOINTS.metrics.createViewMetricByInfluencerNickName}/${nickName}`, data);
            return response.data;
        } catch (error) {
            console.log(error)
            throw error;
        }
    },



};

