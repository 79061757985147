import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { LOCALIZATION, STORAGE_KEYS } from "../api/constants";
import { useNavigate } from "react-router-dom";
import {
  deleteLocalStorageAfterSuccesLoginOut,
  setLocalStorageAfterRefreshToken,
  setLocalStorageAfterSuccesLogin,
} from "../api/localStorage";

interface BusinessInfo {
  businessInfo: string;
  businessId: string;
  isActive: boolean;
  email: string;
  mobileNumber: string;
  id: string;
  type: string;
}

interface AuthContextType {
  accessToken: string | null;
  refreshToken: string | null;
  isAuth: boolean;
  updateAuthState: (newValue: boolean) => void;
  userInfo: BusinessInfo | null;
  setUserInfo: (info: BusinessInfo | null) => void;
  language: string;
  setLanguage: (language: string) => void;
  isBusinessType: string;
  setIsBusinessType: (isBusinessType: string) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const accessToken = localStorage.getItem(STORAGE_KEYS.accessToken);
  const refreshToken = localStorage.getItem(STORAGE_KEYS.refreshToken);
  const type = localStorage.getItem(STORAGE_KEYS.type);
  const [isAuth, setIsAuth] = useState<boolean>(!!accessToken);
  const [language, setLanguage] = useState<string>(LOCALIZATION.English);
  const [isBusinessType, setIsBusinessType] = useState<string>(type);

  const [userInfo, setUserInfo] =
    useState<any | null>(null);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (refreshToken && userInfo === null) {
  //       try {
  //         const response = await AuthServices.refreshToken({
  //           refreshToken: refreshToken,
  //           type: isBusinessType,
  //         });
  //         setLocalStorageAfterRefreshToken(response);
  //         setUserInfo(response.business);
  //         setIsBusinessType(response.business.type);
  //       } catch (error) {
  //         deleteLocalStorageAfterSuccesLoginOut();
  //         setIsAuth(false);
  //       }
  //     }
  //   };

  //   fetchData();
  // }, [refreshToken, userInfo]);

  useEffect(() => {
    if (accessToken) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, [accessToken, refreshToken]);

  useEffect(() => {
    const handleStorageChange = () => {
      const accessToken = localStorage.getItem(STORAGE_KEYS.accessToken);
      setIsAuth(!!accessToken);
    };

    window.addEventListener("storage", handleStorageChange);

    const accessToken = localStorage.getItem(STORAGE_KEYS.accessToken);
    setIsAuth(!!accessToken);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const updateAuthState = (newValue: boolean) => {
    setIsAuth(newValue);
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        refreshToken,
        isAuth,
        updateAuthState,
        userInfo,
        setUserInfo,
        language,
        setLanguage,
        setIsBusinessType,
        isBusinessType,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
